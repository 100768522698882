<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
    :style="$store.state.appConfig.layout.skin == 'dark' ? 'background-color: #0B0B0B' : 'background-color: #E5E7EB'"
  >
    <component :is="layout" v-if="darkRerender()">
      <router-view />
    </component>
    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch , computed} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { useWindowSize, useCssVar } from '@vueuse/core'
import store from '@/store'
// import "@/views/firebase.js";

// -------------------------------------firebase---------------------------------------------
// firebase.js

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAElDYQWph7mtaz2oiGEvFF21RukaPmpQE",
    authDomain: "hustlelab-ca7be.firebaseapp.com",
    projectId: "hustlelab-ca7be",
    storageBucket: "hustlelab-ca7be.appspot.com",
    messagingSenderId: "139466886373",
    appId: "1:139466886373:web:4b7fc3255076d972b3580c",
    measurementId: "G-1REHYNM8XF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
    })
    .catch((error) => {
    });
}


// Request permission to show notifications and get the device token
async function requestPermissionAndGetToken() {
  try {
    
    const permission = await Notification.requestPermission();
    
    if (permission === "granted") {
      const deviceToken = await getToken(messaging, {
        vapidKey:'BBfJKB4s4O2ZYA6YTc_5_umXdEigimysiSt65YA1Y2ucuilko60G42SiUqpNGsetvf9IsSjIiM5OT-YOch_9ZFc'
      });
      if (deviceToken) {
        await storeToken(deviceToken);
      } else {
      }
    } else {
    }
  } catch (error) {
    console.error("Error getting token:", error);
  }
}
requestPermissionAndGetToken();
async function storeToken(deviceToken){
  const payload = {FCMToken:deviceToken}
  await store.dispatch( 'users/storeToken' , payload ).then(res=>{
    }).catch(error => {
  });
}
// Listen for incoming messages
onMessage(messaging, (payload) => {
  // Handle the incoming data message here
});


// -------------------------------------firebase---------------------------------------------
const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  methods:{
    darkRerender(){
      if(this.hasPermission('Active Dark Mode')){
        this.$store.commit('appConfig/UPDATE_SKIN', 'dark')
        return true
      }else {
        this.$store.commit('appConfig/UPDATE_SKIN', 'light')
        return true
      }
    }
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout


    const isDark = computed(() => skin.value === 'dark')


    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    return {
      skinClasses,
      enableScrollToTop,
      skin, isDark 
    }
  },
}
</script>
<style>
.btn-primary,.btn-primary{
    border-color: #E84185 !important;
    background-color: #E84185 !important;
    box-shadow: #E84185 !important;
}

</style>
